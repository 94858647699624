<template>
  <QuestionHeader></QuestionHeader>
  <div class="question_container">
    <div class="question_answers">
      <demographic-traits-questions></demographic-traits-questions>
      <navigation></navigation>
      <QuestionSteps></QuestionSteps>
    </div>
    <div class="question_info">
      <question-info :description="questionDescript" ></question-info>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import DemographicTraitsQuestions from "@/components/questions/DemographicTraitsQuestions";
import QuestionInfo from "@/components/QuestionInfo";
import QuestionSteps from "@/components/QuestionSteps";
import QuestionHeader from "@/components/QuestionHeader";

export default {
  name: "DemographicTraits",
  components: {QuestionHeader, QuestionSteps,QuestionInfo, DemographicTraitsQuestions, Navigation},
  setup(){
    const questionDescript = 'Ved at indsamle demografiske oplysninger om din Buyer Persona kan du tegne et klarere og ' +
        'mere personligt billede af din kunde. Faktorer som alder og uddannelse hjælper dig specifikt med at positionere' +
        ' dit marketing budskab, så den resonerer bedre.'

    return{
      questionDescript
    }
  }
}
</script>

<style scoped>

</style>